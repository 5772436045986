import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import SEO from '../components/seo'
import { tablet, desktop } from '../breakpoints';
import aboutBanner from '../images/aboutus-banner.jpg';

class LivePage extends Component {


	render() { 

	
		return (
			<Page>

				<SEO title="About Us" keywords={[`radio`, `application`, `react`]} />

				<Wrapper>
					<Title>About Us</Title>
					
                    <Banner>                  
                        <div className="inner"><img src={aboutBanner} /></div>
                    </Banner>
				</Wrapper>



                <LiveInfo>
					

					<Inner>
                        
                        <div className="intro inner">
							<p>The Radio Department was launched in 2003 by co-founder Gavin Kingsley, who had previously set-up and overseen ground-breaking ‘radio departments’ at Ministry of Sound and TrusttheDJ.</p>
                        </div>
						
                        <Container>
							<p>Since then, The Radio Department has produced and syndicated award-winning weekly shows, podcasts, live broadcasts, on-location content, and sponsored studios for an ever-growing roster of some of the biggest and most influential artists, personalities, brands and events in the world. </p>
							<p>The Radio Department is trusted by its robust network of affiliate stations, broadcasters and platforms – and their millions of listeners – across all continents. </p>
							<p>It also prides itself in collaborating with management teams, labels, and artists to develop radio careers, while making as much promotional noise as possible for talent internationally. </p>
							<p>The Radio Department’s shows consistently sustain and increase reach week on week for its artists, and this multi-territory promotion has spawned compilation series, merchandising, touring opportunities, and even whole festivals as a direct result. </p>
							<p>And it’s not just music brands that have used The Radio Department as a springboard for successful growth. Recognized as a pioneer in the field of podcasts, having produced audio shows for the NHS years before podcasts became a mainstream essential, The Radio Department continues to work with artists and personalities to produce a wide variety of personalized audio content, in addition to delivering podcasts as internal communications tools for corporates.  </p>
						</Container>

						<Contact>
							<ul className="contactInfo">
								<li>
									<strong>Contact:</strong>
									<div>info@theradiodepartment.com<br/>+44 345 056 9848</div>
								</li>
								{/* <li>
									<strong>Social:</strong>
									<div>
										<em>Instagram</em> <a href="https://www.instagram.com/theradiodepartment/" target="_blank">theradiodepartment</a><br/>
										<em>Facebook</em> <a href="https://www.facebook.com/theradiodepartment/" target="_blank">radiodepartment</a><br/>
										<em>Twitter</em> <a href="https://twitter.com/radiodepartment" target="_blank">radiodepartment</a><br/>
									</div>
								</li> */}
								<li>
									<strong>Address:</strong>
									<div>The Radio Department<br/>27 Old Gloucester Street<br/>London<br/>WC1N 3AX</div>
								</li>
							</ul>
						</Contact>

                       

					</Inner>
					
				</LiveInfo>
		
			</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
	
	}
}

export default connect(mapStateToProps, {  })(LivePage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	min-height:100%;
`

const Wrapper = styled.section`
	padding-top:100px;
	background:#c2b0bc;
`

const LiveInfo = styled.section`
	min-height:100%;
	padding-top:40px;
    background:#f0e452;
    color:#000;
    font-size:22px;
	line-height:1.6em;	

    .inner {
		padding:0 40px 40px 40px;
		padding-bottom:20px;
    }

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 
`

const Banner = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    position: relative;
    padding-top: 25px;
	padding-bottom: 40px;

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:0;
	 }	
	
	img {
		max-width:100%;
	}

    .inner {
        padding:0 40px 40px 40px;
    }
`

const Title = styled.h1`
	font-size: 7vw;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
	margin-bottom:30px;
	font-weight:normal;
	text-align:center;

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
		padding:0 40px;
		margin-bottom:0;
	 }

	 @media only screen and (min-width: ${desktop}) {
		font-size:100px;
	 }
`

const Container = styled.section`
    padding:40px;
    padding-top:0;
    display:block;
	width:100%;
	max-width:850px;
	margin:auto;
	padding-bottom:40px;

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:0;
	 }
`

const Contact = styled.section`
    padding:40px;
    padding-top:0;
    display:block;
	width:100%;
	margin:auto;
	padding-bottom:80px;

	ul.contactInfo {
		margin:0;
		padding:0;

		li {
			display:block;
			width:100%;
			margin-bottom:10px;

			@media only screen and (max-width: ${tablet}) {
				margin-bottom:20px;
			 }

			strong {
				float:left;
				min-height: 50px;
				margin-right:40px;
				font-weight:normal;
				width:88px;

				@media only screen and (max-width: ${tablet}) {
					width:100%;
					min-height:10px;
				 }
			}

			div {
				float:left;
				min-height: 50px;

				em {
					display:inline-block;
					width:130px;
					font-style:normal;
				}

				a {
					text-decoration:underline;
					color:#000;
				}
			}
		}

		li:after {
			content: ' ';
			clear: both;
			display:block;
		}
	}

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:40px;
	 }
`

const Inner = styled.div`
	max-width:1100px;
	width:100%;
    margin:auto;

    .intro {
        p {
            font-size: 30px;
            line-height: 1.5em;

            @media only screen and (max-width: ${tablet}) {
                font-size:25px;
             }	
        }
    }
`